import React from 'react';

type ImageProps = {
    caption: string;
    src: string;
    alt: string;
};

const Image: React.FC<ImageProps> = ({ caption, src, alt }) => {
    return (
        <>
            <figure className="image mt-4 mb-2">
                <img src={src} alt={alt} />
            </figure>
            <aside className="is-italic">{caption}</aside>
        </>
    );
};

export default Image;
