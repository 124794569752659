import React from 'react';
import ViewContainer from '@components/viewContainer';
import Paragraph from '@components/paragraph';
import Image from '@components/image';
import hokamaPortrait from '@static/hokama_portrait.png';

const Hokama = (): React.ReactElement => {
    return (
        <ViewContainer>
            <h1 className="title">Hokama Tetsuhiro</h1>
            <Image
                caption="Hokama Tetsuhiro, 10. dan hanshi"
                src={hokamaPortrait}
                alt="hokama portrait"
            />
            <Paragraph>
                He learned Shuri-te from his grandfather Tokuyama Seiken and since 1961, he had
                learned Goju-ryu from Higa Seko and Fukuchi Seiko, and Kobudo from Matayoshi Shimpo
                and others. Later, he learned Koryu Shinto Tenshin-ryu from Kaminaga Shigenami. In
                2004, he received a PhD in Physical Education from the National University of
                Mindanao. After half a century of training, he has established a wide range of
                martial arts from a historical perspective of Ryukyu, including Karate, Kobudo,
                Kinna-jutsu, and Kyusho-jutsu. The Kenshikai has perhaps the oldest Goju-ryu kata
                and the most complete curriculum in existence today.
            </Paragraph>
            <ul className="mt-4">
                <li>President of Okinawa Gojuryu Kenshikai</li>
                <li>Hanshi 10th Dan in Gojuryu Karate / Hokama Kobudo</li>
                <li>Curator of Okinawa Karate Museum</li>
                <li>Calligrapher as Hokama Shungan</li>
                <li>Researcher of Ryukyu History</li>
                <li>Technical Advisor to the Japan Karate do Kenyukai</li>
                <li>Former member of the Steering Committee of the Okinawa Karate</li>
                <li>Kobudo World Championships</li>
                <li>The World Registry of Black Belts and Hall of Fame in USA</li>
                <li>The Martial arts pioneers Section and Hall of Fame in Netherlands</li>
                <li>Former researcher of Okinawan cultural Assets</li>
                <li>Former member of the Nishihara Board of Education</li>
                <li>PhD in Karate of National University of Mindanao</li>
                <li>31 years of teaching experience in high school and university</li>
                <li>Guest professor of Health and Physical Education in Chuo University</li>
                <li>Lecturer as Okinawa Prefectural University of Arts for 2 years</li>
                <li>Lecturer as Kinki University for 7 years</li>
                <li>Instructor of RINRI Institute of Ethics</li>
                <li>Instructor of Well culture school</li>
                <li>Instructor of Agarihama culture school</li>
            </ul>
            <h2 className="mt-4">The Martial Arts History of Hokama Tetsuhiro</h2>
            <ul>
                <li>1944 Born in Taiwan during the Japanese occupation.</li>
                <li>
                    1961 Begin training in Goju-ryu Karate (Higa Seko and Fukuchi Seiko) and Kobudo
                    (Matayoshi Shimpo).
                </li>
                <li>
                    1971 Member of the team that got Karate recognized as part of the Okinawa
                    Prefecture High School Physical Education Association.
                </li>
                <li>1978 Founded Okinawa Karatedo Gojuryu Kenshikai on 16th Feb.</li>
                <li>
                    Demonstrated at the Demonstration Commemorating the 25th Anniversary of Miyagi
                    Chojun&rsquo;s Death at Meiji Jingu in Tokyo.
                </li>
                <li>1979 All Okinawa Karate-do Championships referee.</li>
                <li>1981 Demonstrated at the International Karate-do Goodwill Tournament.</li>
                <li>1984 Demonstrated at the Chinese Okinawan Martial Arts Festival.</li>
                <li>1987 Establishment of The Okinawa Karate Museum.</li>
                <li>1989 Appointed as Chairperson of the Board of the Okinawa Goju-kai.</li>
                <li>
                    1990 Exhibited all materials of the Okinawa Karate Museum at the request of
                    Governor Nishime at The 1st Worldwide Uchinanchu Festival.
                </li>
                <li>
                    1992 Received Letter of Appreciation from the Okinawa Karate-do Association.
                </li>
                <li>
                    1993 Received an award for Karate promotion from the United States Congress.
                </li>
                <li>Received Letter of Appreciation of Karate from Army of Finland.</li>
                <li>
                    1994 Part time lecturer of Karate and Kobudo at Kinki University Toyooka Junior
                    College.
                </li>
                <li>1996 Erected a monument commemorating Okinawa as the birthplace of Karate.</li>
                <li>
                    1997 Demonstration and Received an award for Karate instruction from the Canada
                    Karate Federation.
                </li>
                <li>1998 Received the Okinawa Prefecture Culture Association President Award.</li>
                <li>1999 Nominated as a Preserver of Cultural Assets.</li>
                <li>Received a Meritorious Award from the Nishihara-cho Culture Association.</li>
                <li>
                    2001 Part time lecturer of Karate and Kobudo at The Okinawa Prefectural
                    University of Arts.
                </li>
                <li>Received The Commercial Education Meritorious Award.</li>
                <li>
                    2002 A newly revised edition Contemporary Japanese Directory 2002 (Kinokuniya
                    Publishing).
                </li>
                <li>
                    2004 Received a PhD in Physical Education (Martial Arts) from Mindinao State
                    University in the Philippines.
                </li>
                <li>Appointed as Visiting Researcher of Karate and Kobudo at Chuo University.</li>
                <li>
                    Lecture at the &rdquo;55th Japan Physical Education Conference&rdquo; at Shinshu
                    University.
                </li>
                <li>2006 Production cooperation for the movie “KURO-OBI”.</li>
                <li>2011 Appearing in NHK World TV “SAMURAI SPIRIT”.</li>
                <li>Received The Minister of Sports Award in Canada.</li>
                <li>The World Registry of Black Belts and Hall of Fame (USA).</li>
                <li>2013 The Martial arts pioneers Section and Hall of Fame (Netherlands).</li>
                <li>2015 Canadian Black Belt and Hall of Fame (Canada).</li>
                <li>
                    2016 Received a Special Meritorious Award from The Okinawa Prefectural Assembly.
                </li>
                <li>Lecture at Taegwondo Headquarters in Korea.</li>
                <li>
                    2017 Calligraphy for Land readjustment completion stone monument of
                    Nishihara-cho Uehara Area.
                </li>
                <li>
                    2018 Calligraphy and Inscription for stone monument of Uechi Ryu Founder Uechi
                    Kanbun sensei.
                </li>
                <li>Lecture of Karate History at Okinawa General Bureau, Cabinet Office.</li>
                <li>
                    2019 The 40th years Anniversary of Okinawa Gojuryu Kenshikai Dojo Commemorative
                    International seminar, Ceremony and Demonstration.
                </li>
                <li>Appearing in a Martial Arts Drama &rdquo;Tenka wo Utsu&rdquo; in Hong Kong.</li>
                <li>2020 Appearing in Masters of Okinawan Karate #9.</li>
                <li>launch International Online Seminars.</li>
                <li>Keynote speech at Commemorative Demonstration of Karate Day 2020.</li>
            </ul>
            <h2 className="mt-4">Publications</h2>
            <ul>
                <li>1984 Okinawa Karatedo no Ayumi (History of Okinawan Karate)</li>
                <li>
                    1984 Gakko ní Okeru Karate do no Shido (Teaching Karatedo in School) in Japanese
                </li>
                <li>
                    1989 Okinawa no Kobudo gu Tanren Dogu (with Kinjo Masakazu) (Okinawan Kobudo
                    Weapons and Training Equipment) in Japanese
                </li>
                <li>1992 Okinawa no Meigen (Famous Okinawan Maxims) in Japanese</li>
                <li>
                    1994 Karate do Kobudo Kihon Chosa Hokokusho (Report of Baeie Research on Karate
                    do Kobudo) in Japanese
                </li>
                <li>1997 History and Traditions of Okinawan Martial Arts in English</li>
                <li>
                    1998 Okinawa Karatedo Kobudo no Shinzuí (The Essence of Okinawan Karatedo and
                    Kobudo) in Japanese
                </li>
                <li>
                    2001 Okinawa Karate Rekishi Nenpyo (Chronology of Karate History) in Japanese
                </li>
                <li>
                    Okinawa Karate Retsuden Hyakunin (100 Masters of Okinawan Karate) in Japanese
                </li>
                <li>2002 Okinawa Goju-Ryu Karate no Kata (with Sakugawa Harunori) in Japanese</li>
                <li>
                    2003 Okinawa Karate-do Shiseki Guidebook (Karate Site Guidance) in Japanese with
                    English
                </li>
                <li>
                    2005 Kyusyo jutsu asian and western point of view (English) Dr. Helmut Kogel and
                    Dr. T.Hokama
                </li>
                <li>100 Masters of Okinawan Karate English Translation</li>
                <li>2007 Timeline of Karate History (Translation: Charles Joe Swift)</li>
                <li>
                    2008 Karate Jutsu Shinan (Vol.1) (Publisher: Bushido Press / Translation:
                    Charles Joe Swift)
                </li>
                <li>
                    2009 Kyusyo jutsu asian and western point of view (Germany) Dr. Helmut Kogel and
                    Dr. T.Hokama
                </li>
                <li>Karate Jutsu Shinan (Vol.2)</li>
                <li>2010 Ryukyu History</li>
                <li>2011 Okinawa Past and Future</li>
                <li>2012 Timeline and 100 Masters (Translation: Charles Joe Swift)</li>
                <li>Okinawan Spiritual Master Guide</li>
                <li>2014 The 7 samurai of Okinawa Karate</li>
                <li>2015 The 7 samurai of Okinawa Karate (English)</li>
                <li>
                    2020 Okinawa Gojuryu Kata Bunkai (Vol.1-3) Dr. Helmut Kogel and Dr. T.Hokama
                    (English and Germany)
                </li>
            </ul>
            <h2 className="mt-4">Video works</h2>
            <ul>
                <li>1997 Ryukyu Kokugi Gojuryu Kenshikai Karate Kobujutsu no Hi (Vol.1)</li>
                <li>Ryukyu Kokugi Gojuryu Kenshikai Karate Kobujutsu no Hi (Vol.2)</li>
                <li>Ryukyu Kokugi Gojuryu Kenshikai Karate Kobujutsu no Hi (Vol.3)</li>
                <li>
                    1998 Ryukyu Kokugi Gojuryu Kenshikai Karate Kobujutsu no Hi (Vol.1) (English)
                </li>
                <li>Ryukyu Kokugi Gojuryu Kenshikai Karate Kobujutsu no Hi (Vol.2) (English)</li>
                <li>Ryukyu Kokugi Gojuryu Kenshikai Karate Kobujutsu no Hi (Vol.3) (English)</li>
                <li>1998 Ryukyu Kobujutsu Hokama Kobudo</li>
                <li>Ryukyu Kobujutsu Hokama Kobudo (English)</li>
                <li>2004 Ryukyu Karate Stone Monuments Guidance</li>
                <li>2011 Okinawa Karate Kobujutsu no Shinan (Vol.1)</li>
                <li>Okinawa Karate Kobujutsu no Shinan (Vol.2)</li>
                <li>Okinawa Karate Kobujutsu no Shinan (Vol.3)</li>
                <li>2020 bujin tv</li>
            </ul>
            <h2 className="mt-4">Social media</h2>
            <ul>
                <li>
                    <a href="https://www.facebook.com/kenshikai.association">Facebook</a>
                </li>
                <li>
                    <a href="https://www.youtube.com/channel/UCDK2mG6RE5NWs7R-6bEbebQ">Youtube</a>
                </li>
            </ul>
            <h2 className="mt-4">Video streaming</h2>
            <ul>
                <li>
                    <a href="https://www.bujin.tv/search/hokama">Bujin tv</a>
                </li>
            </ul>
            <h2 className="mt-4">
                Okinawa Gojuryu Kenshikai Karatedo Kobudo Headquarters Okinawa Prefecture Karate
                Museum
            </h2>
            <p>2-17-6 Uehara, Nishihara-cho, Okinawa Japan 903-0125</p>
            <p>TEL / FAX 098-945-6148</p>
            <p>Email : tetsuhiro.hokama@gmail.com</p>
            <h2 className="mt-4">Training Schedule</h2>
            <p>Kids class 18:00〜19:00 Tuesday / Saturday</p>
            <p>General class 20:00〜22:00 Tuesday / Saturday</p>
        </ViewContainer>
    );
};

export default Hokama;
